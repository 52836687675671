import React, { useEffect, useState } from "react";
// eslint-disable-next-line object-curly-newline
import { Col, Container, Row, Spinner } from "reactstrap";
import MetaTags from "react-meta-tags";
import "./AccountOverview.scss";
import { useParams } from "react-router-dom";
//i18n
import { withTranslation } from "react-i18next";
import AccountOverviewInfo from "./AccountOverviewInfo/AccountOverviewInfo";
import AccountOverviewStats from "./AccountOverviewStats/AccountOverviewStats";
import AccountOverviewConsistencyHistory from "./AccountOverviewConsistencyHistory/AccountOverviewConsistencyHistory";
import AccountOverviewGrowth from "./AccountOverviewGrowth/AccountOverviewGrowth";
import AccountOverviewTradingObjective from "./AccountOverviewTradingObjective/AccountOverviewTradingObjective";
import { fetchChallenge, fetchChallengeGroupedByPlan } from "apis/challenges";
import AccountPrizeOverviewTradingObjective from "pages/PrizesOverview/AccountPrizesOverview/AccountOverviewTradingObjective/AccountPrizeOverviewTradingObjective";
import AccountPrizeOverviewConsistencyHistory from "pages/PrizesOverview/AccountPrizesOverview/AccountOverviewConsistencyHistory/AccountPrizeOverviewConsistencyHistory";
import AccountPrizeOverviewInfo from "pages/PrizesOverview/AccountPrizesOverview/AccountOverviewInfo/AccountPrizeOverviewInfo";
function AccountOverview(props) {
  const { id, clientId } = useParams();
  const [challenge, setChallenge] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isFirstLoading, setIsFirstLoading] = useState(false);
  const [prize, setPrize] = useState();
  const [isPrizeShown, setIsPrizeShown] = useState(false);

  const [activeChallenge, setActiveChallenge] = useState();
  const [plan, setPlan] = useState([]);
  function changeChallenge(id) {
    // const changedChallenge = plan?.challenges?.find((challenge) => challenge?._id === id);
    // console.log("challenge", challenge);
    // setChallenge(changedChallenge);
    setIsPrizeShown(false);
    setActiveChallenge(id);
    setIsLoading(true);
    fetchChallenge(id).then((res) => {
      setChallenge(res?.result);
      setIsLoading(false);
      // setLoading(false);
      // setResult(res);
      // toggleTab(1);
    }).catch((err) => {
      setIsLoading(false);
      // setLoading(false);
      // setResult(err);
      // toggleTab(1);
    });
  }
  useEffect(()=>{
    setIsFirstLoading(true);
    fetchChallengeGroupedByPlan({ 
      groupId: id,
      customerId: clientId,
    }).then((res) => {
      setIsFirstLoading(false);
      setPlan(res?.result?.plans?.[0]);
      setChallenge(res?.result?.challenge);
      setActiveChallenge(res?.result?.challenge?._id);
      setPrize(res?.result?.prize);
      res?.result?.prize && setIsPrizeShown(true);
      // setChallenge(res?.result);
      // setLoading(false);
      // setResult(res);
      // toggleTab(1);
    }).catch((err) => {
      setIsFirstLoading(false);
      // setLoading(false);
      // setResult(err);
      // toggleTab(1);
    });
  }, []);
  return (
    <div className="">
      <MetaTags>
        <title>{props.t("Account Overview")}</title>
      </MetaTags>
      <Container>
        {
          isFirstLoading ? 
            <div className="d-flex justify-content-center align-items-center mt-5">
              <Spinner type="border" className="ms-2" color="secondary" />
            </div>
            :
            <Row>
              <h1 >{plan?.plan?.title}</h1>
              <Col lg="12">
                <div className="navbar-header bg-transparent">
                  <div className="container-fluid">
                    <ul className="nav-tabs-custom nav-start nav nav-tabs page-menues">
                      {
                        plan?.challenges?.map((challenge, index) => (
                          <li className={`nav-item item-${index}`} key={challenge?.id}>
                            <button 
                              className={`nav-link tab-button ${activeChallenge === challenge?._id && !isPrizeShown ? "active fw-bold" : "unselected"}`}
                              type="button" 
                              onClick={()=> changeChallenge(challenge?._id)}
                            >
                              { challenge?.title }
                            </button>
                          </li>
                        )) 
                      }
                      {
                        prize &&
                          <li className={"nav-item item-10"}>
                            <button className={`nav-link tab-button ${isPrizeShown ? "active fw-bold" : "unselected"}`} 
                              type="button" 
                              onClick={()=> setIsPrizeShown(true)}
                            >
                              Prize
                            </button>
                          </li>
                      }
                    </ul>
                  </div>
                </div>
                {/* {
                  plan?.challenges?.map((challenge) => (
                    <button className={`btn mx-2 ${activeChallenge === challenge?._id ? "btn-primary" : "btn-outline-custom"}`} type="button" onClick={()=> changeChallenge(challenge?._id)} key={challenge?._id}>
                      {challenge?.title}
                    </button>
                  ))
                } */}
              </Col>
              {
                isLoading && <div className="d-flex justify-content-center align-items-center"><Spinner type="border" className="ms-2" color="secondary" /></div>
              }
              {
                (challenge && !isPrizeShown) &&
                <>
                  <Col lg="12" className="my-5 mt-2">
                    <AccountOverviewInfo challenge={challenge}/>
                  </Col>
                  <Row>
                    <Col lg="9" >
                      <Row>
                        <Col lg="12"  className="mb-5">
                          <AccountOverviewStats challenge={challenge}/>
                        </Col>
                        <Col lg="12"  className="mb-5">
                          <AccountOverviewTradingObjective challenge={challenge}/>
                        </Col>
                        <Col lg="12"  className="mb-5">
                          <AccountOverviewConsistencyHistory challenge={challenge}/>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg="3">
                      <AccountOverviewGrowth challenge={challenge}/>
                    </Col>
                  </Row>
                </>
              }
              {
                (prize && isPrizeShown ) &&
                <>
                  <Col lg="12" className="my-5 mt-2">
                    <AccountPrizeOverviewInfo challenge={prize}/>
                  </Col>
                  <Row>
                    <Col lg="12" >
                      <Row>
                        {/* <Col lg="12"  className="mb-5">
                          <AccountOverviewStats challenge={challenge}/>
                        </Col>
                        <Col lg="12"  className="mb-5">
                          <AccountOverviewTradingObjective challenge={challenge}/>
                        </Col> */}

                        <Col lg="12"  className="mb-5">
                          <AccountPrizeOverviewTradingObjective challenge={prize}/>
                        </Col>
                        <Col lg="12"  className="mb-5">
                          <AccountPrizeOverviewConsistencyHistory challenge={prize}/>
                        </Col>
                      </Row>
                    </Col>
                    {/* <Col lg="3">
                      <AccountOverviewGrowth challenge={challenge}/>
                    </Col> */}
                  </Row>
                </>
              }
            </Row>
        }
      </Container>
    </div>
  );
}
export default withTranslation()(AccountOverview);
