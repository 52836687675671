import { fetchChallengeStatistics } from "apis/challenges";
import { getKycStats } from "apis/dashboard";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  Card, CardBody, CardTitle, Row, Col
} from "reactstrap";
const Challenges = () => {
  const { t } = useTranslation();
  const [status, setStatus] = useState(null);
  useEffect(async () => {
    const st = await fetchChallengeStatistics({
      planType: 1,
      limit: 10000
    });
    setStatus(st?.result);
  }, []);

  return (
    <React.Fragment>
      <Card className="card-animate">
        <CardBody>
          <CardTitle className="color-primary">
            <h5 className="color-primary">{t("Live Challenges")}</h5>
          </CardTitle>
          <Row className="col-card-same-height mt-5">
            <Link to="/reports?status=IN_PROGRESS&type=LIVE">
              <Col sm={12} xs={12} className="justify-content-start">
                <div className="d-flex align-items-center">
                  <div className="circle-stat">
                    {status?.IN_PROGRESS ?? 0}
                  </div>
                  <span>{t("In Progress")}</span>
                </div>
              </Col>
            </Link>
            <Link to="/reports?status=APPROVED&type=LIVE">
              <Col sm={12} xs={12} className="justify-content-start">
                <div className="d-flex align-items-center">
                  <div className="circle-stat">
                    {status?.SUCCESS ?? 0}
                  </div>
                  {t("Success")}
                </div>
              </Col>
            </Link>
            <Link to="/reports?status=REJECTED&type=LIVE">
              <Col sm={12} className="justify-content-start">
                <div className="d-flex align-items-center">
                  <div className="circle-stat">
                    {status?.FAIL ?? 0}
                  </div>
                  <div>{t("Fail")}</div>
                </div>
              </Col>
            </Link>
            {/* <Col sm={12} xs={12} className="justify-content-start">
              <Link className="d-flex align-items-center" to={"/clients?kyc=KYC_REJECTED"}>
                <div className="circle-stat">
                  {status?.rejected ?? 0}
                </div>
                <div>{t("Rejected")}</div>
              </Link>
            </Col> */}
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default Challenges;