import { getForexDeposits } from "apis/forexDeposit";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { 
  Card, CardBody, CardTitle, Row, Col
} from "reactstrap";
import { Link } from "react-router-dom";

const Plans = () => {
  const { t } = useTranslation();
  const [statusCounts, setStatusCounts] = useState({ 
    APPROVED: 0, 
    REJECTED: 0, 
    PENDING: 0
  });

  useEffect(() => {
    const fetchAndGroupChallenges = async () => {
      const response = await getForexDeposits({
        payload: {
          transType: "plans",
          limit: 100000,
        }
      });
      const challenges = response?.result.docs ?? [];

      const groupedStatus = challenges.reduce((acc, challenge) => {
        const status = challenge.status || "PENDING";
        acc[status] = (acc[status] || 0) + 1;
        return acc;
      }, { 
        APPROVED: 0, 
        REJECTED: 0, 
        PENDING: 0
      });

      setStatusCounts(groupedStatus);
    };

    fetchAndGroupChallenges();
  }, []);

  return (
    <React.Fragment>
      <Card className="card-animate">
        <CardBody>
          <CardTitle className="color-primary">
            <h5 className="color-primary">{t("Purchase Plans")}</h5>
          </CardTitle>
          <Row className="col-card-same-height mt-5">
            <Link to="/transactions/plans?status=APPROVED">
              <Col sm={12} xs={12} className="justify-content-start">
                <div className="d-flex align-items-center">
                  <div className="circle-stat">
                    {statusCounts.APPROVED ?? 0}
                  </div>
                  <span>{t("Approved")}</span>
                </div>
              </Col>
            </Link>
            <Link to="/transactions/plans?status=REJECTED">
              <Col sm={12} xs={12} className="justify-content-start">
                <div className="d-flex align-items-center">
                  <div className="circle-stat">
                    {statusCounts.REJECTED ?? 0}
                  </div>
                  <span>{t("Rejected")}</span>
                </div>
              </Col>
            </Link>
            <Link to="/transactions/plans?status=PENDING">
              <Col sm={12} className="justify-content-start">
                <div className="d-flex align-items-center">
                  <div className="circle-stat">
                    {statusCounts.PENDING ?? 0}
                  </div>
                  <span>{t("Pending")}</span>
                </div>
              </Col>
            </Link>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default Plans;