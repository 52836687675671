import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import UserProfile from "../pages/Authentication/Profile/UserProfile";
import Notifications from "pages/Authentication/Notifications";
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import ResetPassword from "pages/Authentication/ResetPassword";
import UsersList from "../pages/Users/UsersList";
// import LeadsList from "../components/Leads/lead.record.list.component";
import Page404 from "../pages/Authentication/Page404";
import usePermissions from "./permissions";
import Dashboard from "pages/Dashboard";
import ClientList from "../pages/Clients/ClientList";
import ClientAdd from "pages/Clients/ClientAdd";
import ClientMainPage from "pages/ClientDetail/ClientMainPage";
import Plan from "pages/Plans/PlanList";
import CreatePlan from "pages/Plans/CreatePlan";
import AccountTypes from "pages/AccountTypes";
import RolesList from "pages/Roles/RolesList";
import EmailConfig from "pages/EmailConfig/EmailConfig";
import DepositsIndex from "pages/Transactions/DepositsIndex";
import DepositsWalletIndex from "pages/Transactions/DepositsWalletIndex";
import WithdrawalsIndex from "pages/Transactions/WithdrawalsIndex";
import CompanyBanks from "pages/CompanyBanks";
import AssetsList from "pages/Assests/AssetsList";
import SystemEmailMainPage from "pages/SystemEmail/SystemEmailMainPage";

import LeadsList from "../pages/Leads/LeadList";
import Calendar from "../pages/Calendar";
import IbRequest from "pages/Requests/IbRequest";
import UserLogs from "pages/UserLogs";
import Leverage from "pages/Requests/Leverage";
import AccountRequest from "pages/Requests/Accounts/AccountRequest";

import CampaignTemplates from "pages/Campaigns/Templates";
import Campaigns from "pages/Campaigns";
import Unsubscribers from "pages/Campaigns/Unsubscribers";
import MarketingLinks from "pages/Campaigns/UtmCampaigns/MarketingLinks";
import SystemEmailsList from "../pages/SystemEmail/SystemEmailList";
import DiscountCouppounsList from "pages/DiscountCouppouns/DiscountCouppounsList";
import ClientIBAdd from "pages/Clients/ClientIBAdd";
import Reports from "pages/Reports/Reports";

function userRoutes() {
  const object = usePermissions();
  const {
    userPermissions,
    clientPermissions,
    AccTypesPermissions,
    rolesPermissions,
    systemEmailsPermissions,
    symbolsPermissions,
    emailConfigPermissions,
    depositsPermissions,
    leadsPermissions,
    emailCampaignPermissions,
    userLogsPermissions,
    // reportsPermissions,
    withdrawalsPermissions
  } = object;


  return [
    {
      path: "/dashboard",
      component: Dashboard,
      get: userPermissions.get
    },
    {
      path: "/profile",
      component: UserProfile,
    },
    {
      path: "/notifications",
      component: Notifications,
    },
    {
      path: "/clients",
      component: ClientList,
      get: clientPermissions.get || clientPermissions.getAssigned
    },
    {
      path: "/clients/create",
      component: ClientAdd,
    },
    {
      path: "/clients/ib/create",
      component: ClientIBAdd,
    },
    {
      path: "/clients/:id",
      component: ClientMainPage,
      notExact: true,
    },
    {
      path: "/reports",
      component: Reports,
      // get: reportsPermissions.get || reportsPermissions.getAssigned,
    },
    {
      path: "/plans",
      component: Plan
    },
    {
      path: "/plans/create",
      component: CreatePlan
    },
    {
      path: "/plans/update/:id",
      component: CreatePlan
    },
    {
      path: "/users",
      component: UsersList,
      get: userPermissions.get
    },
    {
      path: "/discount-couppouns",
      component: DiscountCouppounsList,
      // get: userPermissions.get
    },
    {
      path: "/account-types",
      component: AccountTypes,
      get: AccTypesPermissions.get
    },
    {
      path: "/banks",
      component: CompanyBanks,
      // get: companyBanksPermissions.get
    },
    {
      path: "/roles",
      component: RolesList,
      get: rolesPermissions.get
    },
    {
      path: "/email-campaigns/",
      component: Campaigns,
      get: emailCampaignPermissions.get
    },
    {
      path: "/email-campaigns/templates",
      component: CampaignTemplates,
      get: emailCampaignPermissions.get
    },
    {
      path: "/email-campaigns/unsubscribers",
      component: Unsubscribers,
      get: emailCampaignPermissions.get
    },
    {
      path: "/email-campaigns/unsubscribers",
      component: Unsubscribers,
      get: emailCampaignPermissions.get
    },
    {
      path: "/email-campaigns/links",
      component: MarketingLinks,
      // get: emailCampaignPermissions.get
    },
    {
      path: "/user-logs",
      component: UserLogs,
      get: userLogsPermissions.get,
    },
    
    {
      path: "/system-emails/:id",
      component: SystemEmailMainPage,
      notExact: true,
    },
    {
      path: "/system-emails",
      component: SystemEmailsList,
      get: systemEmailsPermissions.get
    },
    {
      path: "/email-config",
      component: EmailConfig,
      get: emailConfigPermissions.get
    },
    {
      path: "/transactions",
      component: DepositsIndex,
      get: depositsPermissions.get || depositsPermissions.getAssigned,
    },
    {
      path: "/transactions/plans",
      component: DepositsIndex,
      get: depositsPermissions.get || depositsPermissions.getAssigned,
    },
    {
      path: "/transactions/deposit",
      component: DepositsWalletIndex,
      get: depositsPermissions.get || depositsPermissions.getAssigned,
    },
    {
      path: "/transactions/withdrawals",
      component: WithdrawalsIndex,
      get: withdrawalsPermissions.get || withdrawalsPermissions.getAssigned,
    },
    {
      path: "/assets",
      component: AssetsList,
      get: symbolsPermissions.get
    },
    
    {
      path: "/calendar",
      component: Calendar,
    },

    {
      path: "/leads",
      component: LeadsList,
      get: leadsPermissions.get || leadsPermissions.getAssigned
    },
    
    {
      path: "/requests/accounts",
      component: AccountRequest,
    },
    {
      path: "/requests/ib",
      component: IbRequest,
    },
    {
      path: "/requests/leverage",
      component: Leverage,
    },

    // this route should be at the end of all other routes
    {
      path: "/",
      exact: true,
      component: () => <Redirect to="/dashboard" />,
    },
  ];
}


const authRoutes = [
  {
    path: "/logout",
    component: Logout,
  },
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/forgot-password",
    component: ForgetPwd,
  },
  {
    path: "/reset-password",
    component: ResetPassword,
  },
  {
    path: "/register",
    component: Register,
  },
  {
    path: "*",
    exact: true,
    component: Page404,
  },
];

export { userRoutes, authRoutes };
