/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import { useDispatch, connect } from "react-redux";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  Spinner,
} from "reactstrap";

// eslint-disable-next-line object-curly-newline
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

import { getSalesAgentsStart } from "store/users/actions";

import { MetaTags } from "react-meta-tags";

import ReportForm from "./ReportForm";
import TableLoader from "components/Common/TableLoader";
import formatDate from "helpers/formatDate";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useLocation  } from "react-router-dom";
import * as axiosHelper from "../../apis/api_helper";
import { captilazeFirstLetter } from "common/utils/manipulateString";
import Badge from "components/Common/Badge";

function getColumns(reportType) {
  switch (reportType) {
    case "deposits":
      return [
        {
          dataField: "customerName",
          text: "Client Name",
          formatter: (val) =>
            val?.customer
              ? (
                <Link
                  to={`/clients/${val?.customer?._id}/profile`}
                >{`${val.customer.firstName} ${val.customer.lastName}`}</Link>
              ) || "-"
              : "-",
        },
        {
          dataField: "login",
          text: "Login",
        },
        {
          dataField: "amount",
          text: "Amount",
          formatter: (val) => parseFloat(val?.amount)?.toFixed(2) ?? "-",
        },
        {
          dataField: "currency",
          text: "Currency",
        },
        {
          dataField: "agent",
          text: "Agent",
          formatter: (val) =>
            val.agent ? `${val.agent.firstName} ${val.agent.lastName}` : "-",
        },
        {
          dataField: "createdAt",
          text: "Added in DB",
          formatter: (val) => formatDate(val.createdAt),
        },
      ];
    case "demo-approved-plans":
    case "demo-rejected-plans":  
    case "demo-pending-plans":
    case "live-approved-plans":
    case "live-rejected-plans":  
    case "live-pending-plans":
      return [
        {
          dataField: "customerName",
          text: "Client Name",
          formatter: (val) =>
            val?.customerId
              ? (
                <Link
                  to={`/clients/${val?.customerId}/profile`}
                >{`${val.customerName}`}</Link>
              ) || "-"
              : "-",
        },
        {
          dataField: "challengeName",
          text: "Challenge Name",
          formatter: (val) => val.challengeName,
        },
        {
          dataField: "planName",
          text: "Plan Name",
          formatter: (val) => val.planName || "-",
        },
        {
          dataField: "status",
          text: "Status",
          formatter: (val) => val.status || "-",
        },
        {
          dataField: "createdAt",
          text: "Added in DB",
          formatter: (val) => formatDate(val.createdAt),
        },
        {
          dataField: "updatedAt",
          text: "Last Updated",
          formatter: (val) => formatDate(val.updatedAt),
        },
      ];
    default:
      return [
        {
          dataField: "login",
          text: "Login",
        },
        {
          dataField: "profit",
          text: "Amount",
        },
        {
          dataField: "comment",
          text: "Comment",
        },
        {
          dataField: "createdAt",
          text: "Added in DB",
          formatter: (val) => formatDate(val.createdAt),
        },
      ];
  }
}

function ShowStats(props) {
  const { type, dateFrom, dateTo, agent } = props;
  if (!type) return <></>;
  const [loading, setLoading] = useState(false);
  const [stats, setStats] = useState({});

  useEffect(() => {
    setLoading(true);
    if (type) {
      (async () => {
        axiosHelper
          .get(`/reports/stats/${type}`, {
            params: {
              dateFrom,
              dateTo,
              agent,
            },
          })
          .then((res) => {
            setStats(res?.result?.[0]);
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            // eslint-disable-next-line no-console
            console.log(err);
          });
      })();
    }
  }, [type, dateFrom, dateTo, agent]);

  if (!Object.keys(stats || {}).length) return <></>;

  if (loading) return <Spinner />;

  const formatData = (data) => {
    switch (type) {
      case "deposits":
        return `Total Deposit : ${parseFloat(
          data?.totalDeposit?.$numberDecimal || data?.totalDeposit || 0
        )?.toFixed(2)} ${data.currency}`;
      case "withdrawals":
        return `Total Withdrawal : ${parseFloat(
          data?.totalWithdrawal?.$numberDecimal || data?.totalWithdrawal || 0
        )?.toFixed(2)} ${data.currency}`;
      case "client-deposits":
        return `Total Deposit : ${parseFloat(
          data?.totalDeposit?.$numberDecimal || data?.totalDeposit || 0
        )?.toFixed(2)} ${data.currency}`;
      case "client-withdrawals":
        return `Total Withdrawal : ${parseFloat(
          data?.totalWithdrawal?.$numberDecimal || data?.totalWithdrawal || 0
        )?.toFixed(2)} ${data.currency}`;
      case "credit-in":
        return `Total Amount : ${data?.totalCreditAmount || 0}`;
      case "credit-out":
        return `Total Amount : ${data?.totalCreditAmount || 0}`;
      case "ib-summary":
        return `Total Commission : ${data?.totalCommission || 0} | Total Rebate : ${data?.totalRebate || 0} | Total Lots Opened : ${data?.totalLotsOpened || 0} | Total Lots Closed : ${data?.totalLotsClosed || 0}`;
      default:
        return "";
    }
  };

  return <>{formatData(stats)}</>;
}

function ReportsList(props) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const status = queryParams.get("status");
  const type = queryParams.get("type");
  const dispatch = useDispatch();
  const [columns, setColumns] = useState([]);
  const [payload, setPayload] = useState({});

  useEffect(() => {
    dispatch(
      getSalesAgentsStart({
        page: 1,
        limit: 1000,
      })
    );
  }, []);

  return (
    <React.Fragment>
      <MetaTags>
        <title>Reports</title>
      </MetaTags>
      <div className="page-content">
        <div className="container-fluid">
          <h2>Reports</h2>
          <Row>
            <Col className="col-12">
              <Card>
                <CardHeader className="d-flex justify-content-between  align-items-center">
                  <CardTitle className="color-primary">Reports</CardTitle>
                </CardHeader>
                <CardBody>
                  <ReportForm
                    setReportColumns={(type) => {
                      setColumns(getColumns(type));
                    }}
                    setPayload={setPayload}
                    status={status}
                    type={type}
                  >
                    <Table
                      id="tech-companies-1"
                      className="table  table-hover "
                    >
                      <Thead className="text-center table-light">
                        <Tr>
                          {columns.map((column, index) => (
                            <Th data-priority={index} key={index}>
                              {column.text}
                            </Th>
                          ))}
                        </Tr>
                      </Thead>
                      <Tbody
                        className="text-center"
                        style={{ fontSize: "13px" }}
                      >
                        {props.loading && <TableLoader colSpan={4} />}
                        {!props.loading && props.docs.length === 0 && (
                          <>
                            <Tr
                              style={{
                                height: "50vh",
                              }}
                            >
                              <Td
                                colSpan={"100%"}
                                className="fw-bolder text-center"
                              >
                                <h3 className="fw-bolder text-center">
                                  No records
                                </h3>
                              </Td>
                            </Tr>
                          </>
                        )}
                        {!props.loading &&
                          props.docs.map((row, rowIndex) => (<Tr key={rowIndex}>
                            {columns.map((column, index) => (
                              <Td key={`${rowIndex}-${index}`}>
                                {column.formatter
                                  ? column.formatter(row, rowIndex)
                                  : row[column.dataField]}
                              </Td>
                            ))}
                          </Tr>)
                          )
                        }

                        <Tr>
                          <Td colSpan={columns.length}>
                            <ShowStats
                              type={payload?.type}
                              dateFrom={payload?.dateFrom}
                              dateTo={payload?.dateTo}
                              agent={payload?.agent}
                            />
                          </Td>
                        </Tr>
                      </Tbody>
                    </Table>
                  </ReportForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  loading: state.reportsReducer.loading || false,
  docs: state.reportsReducer.docs || [],
  reportsReducer: state.reportsReducer || {},
});
export default connect(mapStateToProps, null)(ReportsList);
